<script>
  import {
    WidgetHeader,
    WidgetFooter,
    GridContainer,
    GridColumn,
    Icon,
  } from '@qlean/ui-kit-web-svelte'

  import AccordionContainer from './AccordionContainer'
  import ImageContainer from './ImageContainer'

  import classNames from 'classnames/bind'
  import css from './_styles.scss'

  export let config

  const { title, titleTag, subTitle, list, images, withTopMargin, withBottomMargin } = config

  const cN = classNames.bind(css)
</script>

{@html Icon.spriteContent}

<WidgetHeader {title} {titleTag} {subTitle} withMargin={withTopMargin} />
<GridContainer class={cN('features')}>
  <GridColumn widthMiddle={6}>
    <ImageContainer {images} {cN} />
  </GridColumn>
  <GridColumn widthMiddle={1} />
  <GridColumn widthMiddle={4}>
    <AccordionContainer {list} {cN} />
  </GridColumn>
  <GridColumn widthMiddle={1} />
</GridContainer>
<WidgetFooter withMargin={withBottomMargin} />
