<script>
  import { GridColumn, Text, Header, Separator, Icon } from '@qlean/ui-kit-web-svelte'

  export let cN
  export let list

  let expandedIdx = 0

  function toggleIsExpanded(idx) {
    expandedIdx = idx === expandedIdx ? null : idx
  }

  function prepareText(text) {
    if (text.length > 200) {
      const shortText = `${text.slice(0, 197)}...`

      return shortText
    }

    return text
  }
</script>

{#each list as { title, description }, idx}
  <div class={cN('features__unit')}>
    <Separator key={20} size={Separator.SIZES.SMALL} />
    <div on:click={() => toggleIsExpanded(idx)} class={cN('features__unit-header')}>
      <Header size={Header.SIZES.SMALL}>{title}</Header>
      <Icon
        name={Icon.NAMES.arrow}
        key={Icon.KEYS.MEDIUM}
        class={cN('features__unit-header-arrow', {
          'features__unit-header-arrow_active': idx === expandedIdx,
        })} />
    </div>
    <div
      class={cN('features__unit-content', {
        'features__unit-content_visible': idx === expandedIdx,
      })}>
      <Separator key={5} size={Separator.SIZES.SMALL} />
      <Text size={Text.SIZES.LARGE}>{prepareText(description)}</Text>
    </div>
    <Separator key={20} size={Separator.SIZES.SMALL} />
  </div>
{/each}
