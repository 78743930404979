<script>
  import { Picture } from '@qlean/ui-kit-web-svelte'

  export let cN
  export let images

  const len = images.length

  function getType(idx) {
    switch (len) {
      case 1:
      case 4:
        return 'SQUARE'
      case 2:
        return 'SQUARE_WIDE'
      case 3:
        return !!idx ? 'SQUARE' : 'SQUARE_WIDE'
    }
  }
</script>

<div class={cN('features__image', `features__image_${len}-pic`)}>
  {#each images as image, idx}
    <Picture
      class={cN('features__image-item', `features__image-item_${len}-pic`)}
      {...image}
      type={Picture.PROPORTIONS[getType(idx)]} />
  {/each}
</div>
